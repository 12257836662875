<template>
  <v-scale-transition>
    <kits-panel :kits-items="financeKits">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Purchase Orders
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
export default {
  components: {
    KitsPanel: () => import('@/components/KitsPanel'),
  },
  data () {
    return {
      financeKits: [
        {
          icon: 'mdi-pin-outline',
          path: '/finance/black_pin_orders',
          title: 'Black Pin Orders',
          desc: 'Manage orders for Black pins.',
        },
      ],
    }
  },
}
</script>
